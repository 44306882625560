import BookingProcessForm from '@/Booking/BookingProcess/BookingProcessForm.vue';
import BookingSummary from '@/Booking/components/BookingSummary.vue';
import { useGetBookingQuery, useUpdateBookingMeetingMutation, OrderItemType, useDeleteDraftBookingMutation } from '@/generated-types/graphql.types';
import { computed, defineComponent, onUnmounted, reactive, ref } from '@vue/composition-api';
import Card from '@/uikit/Card.vue';
import Collapse from '@/uikit/Collapse.vue';
import { ServerErrors } from '@/util/graphql';
import { BookingRoutes } from '@/Booking/booking.const';
import useStructuredData from '@/shared/composables/useStructuredData';
import EventBus from '@/shared/services/eventBus';
const ManualBooking = defineComponent({
    components: {
        BookingProcessForm,
        BookingSummary,
        Card,
        Collapse
    },
    setup(_, context) {
        const { root } = context;
        const bookingId = +root.$route.params.bookingId;
        const afterpayDescriptionValidationError = ref('');
        const editBookingButtonMode = Object.prototype.hasOwnProperty.call(localStorage, 'cachedBookingSummary');
        const bookingSummary = reactive({
            space: {},
            attendees: null,
            slot_start: null,
            slot_end: null,
            start_time: null,
            end_time: null,
            is_discount_applied: false,
            is_coupon_applied: false,
            discount_rate: null,
            order: {
                discount_total: null,
                order_items: [],
                order_total: null,
                order_total_vat_excl: null
            },
            vat_21: null,
            vat_9: null,
            total_vat_excl: null,
            total: null
        });
        const getBookingQueryVariables = reactive({
            id: bookingId
        });
        const { result, onResult: onGetBookingResult } = useGetBookingQuery(getBookingQueryVariables);
        onGetBookingResult((result) => {
            const booking = result?.data?.specific_booking;
            const vat9items = booking?.order?.order_items?.filter(item => item.vat_rate === 9) || [];
            const vat9total = vat9items.reduce((acc, item) => item.vat_rate === 9
                ? (item.order_item_price * item.vat_rate) / 100 + acc
                : 0, 0);
            bookingSummary.attendees_selected_count = booking.attendees;
            bookingSummary.is_discount_applied = booking?.is_discount_applied;
            bookingSummary.is_coupon_applied = !!booking?.booking_meta?.coupon;
            bookingSummary.slot_start = booking?.slot_start;
            bookingSummary.slot_end = booking?.slot_end;
            bookingSummary.start_time = booking?.start_time;
            bookingSummary.end_time = booking?.end_time;
            bookingSummary.slot_end = booking?.slot_end;
            bookingSummary.space = booking?.space;
            bookingSummary.discount_rate =
                booking?.booking_meta?.coupon?.discount_percentage ||
                    booking?.space?.discount_percentage;
            bookingSummary.total = booking?.order.order_total;
            bookingSummary.total_vat_excl = booking?.order.order_total_vat_excl;
            bookingSummary.vat_9 = vat9total;
            bookingSummary.vat_21 = booking?.order.vat_total - vat9total;
            bookingSummary.order.discount_total = booking?.order.discount_total;
            bookingSummary.order.order_total = booking?.order.order_total;
            bookingSummary.order.order_total_vat_excl =
                booking?.order.order_total_vat_excl;
            bookingSummary.order.order_items = booking?.order.order_items?.map(item => {
                return {
                    quantity: item.quantity,
                    order_item_type: item.order_item_type,
                    reference_id: item.reference_id,
                    price: Number((item.order_item_price + item.service_fee).toFixed(2)),
                    service_type: item.service_type,
                    name: item.service_name,
                    configuration_type: booking?.space?.space_configurations?.find(configuration => configuration?.configuration_id === item.reference_id &&
                        item.order_item_type === OrderItemType.Configuration)?.configuration_type
                };
            });
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const updateBooking = useUpdateBookingMeetingMutation({});
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        const { mutate: deleteDraftBookingMutation } = useDeleteDraftBookingMutation({});
        const deleteBooking = () => deleteDraftBookingMutation({ id: bookingId }, {});
        const editBooking = async () => {
            // First delete current booking
            await deleteBooking();
            // Then redirect
            globalThis.$router.back();
        };
        const handleSubmit = ({ meeting: meeting_name, description: meeting_description, organization: order_meta, isAfterpayRequested: is_afterpay_requested }) => {
            updateBooking
                .mutate({
                id: bookingId,
                data: {
                    meeting_name,
                    meeting_description,
                    is_afterpay_requested,
                    order_meta: order_meta
                }
            }, {})
                .then(() => {
                localStorage.removeItem('booking');
                globalThis.$router.push(`${bookingId}/${BookingRoutes.SUMMARY}`);
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                    if (graphQLError?.error?.key === 'code_injection') {
                        afterpayDescriptionValidationError.value = root.$i18n.t('common.errors.code_injection');
                    }
                }
            });
        };
        return {
            afterpayDescriptionValidationError,
            booking: computed(() => result?.value?.specific_booking),
            bookingSummary,
            editBookingButtonMode,
            user,
            editBooking,
            handleSubmit
        };
    }
});
export default ManualBooking;
